<template>
  <Noise />
</template>

<script>
import Noise from '../components/Noise'

export default {
  name: 'HomeView',

  components: {
    Noise
  }
}
</script>
